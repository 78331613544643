body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.twitter-bs-wizard .twitter-bs-wizard-nav .step-icon {
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 54px;
  border: 1px solid rgba(81, 86, 190, 0.2);
  color: #5156be;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  font-size: 20px;
}

@media (max-width: 575.98px) {
  .twitter-bs-wizard .twitter-bs-wizard-nav .step-icon {
      width: 40px;
      height: 40px;
      line-height: 38px;
  }
}

.twitter-bs-wizard .twitter-bs-wizard-nav .step-title {
  margin-left: 6px;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-item:last-child .nav-link::after {
  display: none;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-item .nav-link.done .step-icon {
  background-color: #5156be;
  color: #fff;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-item .nav-link.done .uil:before {
  content: "\e9c3";
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link {
  font-size: 14px;
  position: relative;
}

@media (max-width: 575.98px) {
  .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link {
      padding: 0.5rem;
  }
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link::after {
  content: "";
  position: absolute;
  width: 75%;
  height: 2px;
  background-color: #e9e9ef;
  left: 62%;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 575.98px) {
  .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link::after {
      display: none;
  }
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active {
  color: #495057;
  background-color: transparent;
}

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-icon {
  background-color: rgba(81, 86, 190, 0.2);
  color: #5156be;
  border-color: rgba(81, 86, 190, 0.2);
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link {
  padding-top: 24px;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link li {
  display: inline-block;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link li.next {
  float: right;
}

.twitter-bs-wizard-tab-content {
  padding-top: 24px;
  min-height: 262px;
}