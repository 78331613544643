@import url("https://fonts.googleapis.com/css2?display=swap&family=Nunito:wght@400;600;700&display=swap");

:root {
  /*   --header-height: 3rem;
 */
  --nav-width: 138px;
  /*  */
  --lnav-width: 68px;
  --first-color: "white";
  --first-color-light: #AFA5D9;
  --white-color: "white";
  --black-color: "black";
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100,
}

.body-area {
  position: relative;
  margin: var(--header-height) 0 0 0;
  /*   padding: 0 1rem;
 */
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: .5s
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  transition: .5s
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden
}

.header_img img {
  width: 40px
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--lnav-width);
  height: 100vh;
  background-color: var(--first-color);
  padding: .5rem 1rem 0 0;
  transition: .5s;
  z-index: var(--z-fixed)
}

/* .nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden
} */

.custom_nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background-color: #274755;
  color: white;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0 0 0.5rem 0.2rem;
  text-decoration: none;
}

.nav_logo {
  margin: 0.7rem 0;
  color: var(--black-color);
  font-size: 1.30rem
}

.nav_logo-icon {
  font-size: 1.35rem;
  color: var(--white-color)
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700
}

.nav_link {
  position: relative;
  color: #d9d9d9;
  margin-bottom: 0.2rem;
  transition: .3s
}

a.nav_link:hover {
  color: white;
}

.nav_icon {
  font-size: 1.25rem
}

/* .show {
  left: 0
} */
.l-show {
  left: 0
}

.body-pd {
  transition: .5s;
  padding-left: calc(var(--nav-width) - 5.4rem)
    /*  */
}

.active {
  color: var(--white-color)
}

.active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color)
}

.height-100 {
  height: 100vh
}

@media screen and (min-width: 768px) {
  .body-area {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--lnav-width) - 1rem)
      /*  */
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
  }

  .header_img {
    width: 40px;
    height: 40px
  }

  .header_img img {
    width: 45px
  }

  .l-navbar {
    left: 0;
    padding: 0 1rem 0 0
  }

  /* .show {
    width: calc(var(--nav-width) + 156px)
  } */
  .l-show {
    width: calc(var(--nav-width) + 156px)
  }


  .body-pd {
    padding-left: calc(var(--nav-width) + 140px)
      /*  */
  }
}

/* bi bi-check2 green-color */
.green-color {
  color: green;
}

.red-color {
  color: red;
}

.navbar {
  background-color: #274755;
  color: white
}